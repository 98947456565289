* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* LOGIN COMPONENT */

.App {
  margin-top: 15%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.App h4 {
  font-weight: 400;
}

.input {
  margin-top: 5%;
}

/* EDITOR COMPONENT*/

.editorContainer {
  margin-top: 4%;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.editorInput {
  margin-top: 3%;
}

.dimensionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dimensionInput {
  width: 21%;
}

.dimensionSeperator {
  font-weight: bolder;
  color: gray;
  padding-left: 2%;
  padding-right: 2%;
}

.addToGalleryButton {
  width: 48.5%;
}

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.previewText {
  color: #fff;
  font-weight: bold;
  font-size: 0.5em;
}

.previewHeaderText {
  color: #fff;
  font-weight: bold;
  font-size: 0.75em;
}

.previewImage {
  width: 200px;
  border-radius: 10px;
}

.previewImage:hover {
  opacity: 0.75;
  cursor: pointer;
}

.imageButton:hover {
  background-color: #d0d0d0;
}

.uploadImageText {
  font-weight: 500;
}

input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
}

.custom-file-upload-error {
  border: 1px solid rgb(195, 63, 56);
}

.custom-file-upload:hover {
  background-color: #eee;
}

.custom-file-upload-error:hover {
  border-color: rgb(138, 44, 39);
}

.custom-file-upload-error p {
  color: rgb(195, 63, 56);
  align-self: flex-start;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.editButtonContainer {
  display: flex;
  width: 51%;
  justify-content: space-evenly;
}

.editButton {
  flex: 0.45;
}

/* HEADER COMPONENT */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  border-width: 2;
  flex-direction: column;
}

.header #event {
  border-bottom: 1px solid gray;
  font-weight: 500;
  font-size: 1.1em;
}

.header p {
  font-size: 0.8em;
  font-weight: 300;
  margin-left: 2%;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 1%;
}


/* COMPONENTS */
.circularProgress {
  margin-top: 2%;
}

.QRCODE {
  display: none;
}

.footer {
  font-weight: 100;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 2%;
  margin-bottom: 2%;
}
